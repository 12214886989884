import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import LanguageselectorStyles from "./languageselector.module.css"
import Langlink from "../components/langlink"

export default function Languageselector( props ) {
    const intl = useIntl()
    const langs = JSON.parse( intl.formatMessage({ id: "langs" }) )

    const languages_links = langs.map((lang) =>
        <Langlink 
            text={lang.lang}
            link={lang.lang_url}
        />
    );
    return (
        <div className={LanguageselectorStyles.language_selector_container}>
            <ul className={LanguageselectorStyles.language_selector}>
                {languages_links}
            </ul>
        </div>
    )
}