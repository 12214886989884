import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import PromoStyles from "./promo.module.css"

export default function Promo() {
  const intl = useIntl()
  return (
    <div className={[PromoStyles.section, "container", "pt-5"].join(" ")}>
      <div className={["row"].join(" ")}>
        <div
          className={[
            "col-sm-12",
            "col-md-5",
            "text-center",
            "p-4",
            PromoStyles.image,
          ].join(" ")}
        >
          <img
            className={[
              PromoStyles.image,
              "mx-auto",
              "d-block",
              "img-fluid",
              "img-fluid",
            ].join(" ")}
            src={"/Tarjeta-Regalo-Escola-Port-2020.jpg"}
            alt="..."
          />
        </div>
        <div className={["col-sm-12", "col-md-7", "p-4"].join(" ")}>
          <h4 className={["text-center"].join(" ")}>
            {intl.formatMessage({ id: "promo_title" })}
          </h4>
          <h5 className={["text-center"].join(" ")}>
            {intl.formatMessage({ id: "promo_regala" })}
          </h5>
          <div className={["row"].join(" ")}>
            <a
              className={["button", PromoStyles.button].join(" ")}
              href={intl.formatMessage({ id: "promo_buy_link" })}
            >
              {intl.formatMessage({ id: "promo_buy" })}
            </a>
          </div>
          <div className={["row", "m-2"].join(" ")}>
            <a
              className={[PromoStyles.more_info].join(" ")}
              href={intl.formatMessage({ id: "promo_moreinfo_link" })}
            >
              {intl.formatMessage({ id: "promo_moreinfo" })} &#62;
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
