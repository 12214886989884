import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import ComparteStyles from "./comparte.module.css"

export default function Sorpresa() {
    const intl = useIntl()
    return (
        <div className="container">
            <div className="row justify-content-center mb-4">
                <div className="col-11 text-center border border-danger p-3">
                    <span className="font-weight-bold">{intl.formatMessage({ id: "regalo_title" })}</span>
                    <p>{intl.formatMessage({ id: "regalo_body" })}</p>
                    <p>{intl.formatMessage({ id: "regalo_footer" })}</p>
                </div>
            </div>
        </div>
    )
}