import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"
import NavBarRegala from "../components/navBarRegala"
import Layout from "../components/layout"
import Header from "../components/header"
import Title from "../components/title"
import Container from "../components/container"
import HowTo from "../components/how-to"
import Sorpresa from "../components/sorpresa"
import Separador from "../components/separador"
import Comparte from "../components/comparte"
import Promo from "../components/promo"
import CarnetJove from "../components/carnetJove"
import Footer from "../components/footer"
import "bootstrap/dist/css/bootstrap.min.css"
import { Navbar } from "react-bootstrap"

export default function Home() {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: intl.locale }}>
        <meta charSet="utf-8" />
        <title>{intl.formatMessage({ id: "title_page" })}</title>
      </Helmet>
      <Header />
      <NavBarRegala />
      <Title />
      {/* <Container /> */}
      <HowTo />
      <Sorpresa />
      <Separador />
      {/* <Comparte /> */}
      <Promo />
      <Separador />
      <CarnetJove />
      <Footer />
    </Layout>
  )
}
