import React from "react"
import titleStyles from "./title.module.css"
import { useIntl } from "gatsby-plugin-intl"

export default function Title() {
  const intl = useIntl()

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="container">
            <div className="row">
              <div
                className={[
                  titleStyles.title_page_container,
                  "pt-4",
                  "col",
                  "text-left",
                ].join(" ")}
              >
                <h3 className={["font-weight-normal"].join(" ")}>
                  {intl.formatMessage({ id: "title_presentation" })}
                </h3>
                <p
                  className={[titleStyles.title_page, "m-0", "text-left"].join(
                    " "
                  )}
                >
                  {intl.formatMessage({ id: "subtitle_presentation" })}
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className={[
                  titleStyles.title_page_container,
                  "pt-4",
                  "col",
                ].join(" ")}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "description_presentation",
                  }),
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <div className={`${titleStyles.img_container} img-fluid`}>
            <img src="/img_rem_barco.png" alt="" />
            <p>{intl.formatMessage({ id: "subtitleImage" })}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
