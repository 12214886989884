import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import stylesCarnetJove from "./carnetJove.css"

export default function CarnetJove() {
  const intl = useIntl()
  return (
    <div className="imgCarnetJove">
      <img className="img-fluid" src="/carnetJoven.jpg" alt="" />
      <div className="containerJove text-white">
        <h2>{intl.formatMessage({ id: "texto_header_cj" })}</h2>
        <h2>{intl.formatMessage({ id: "texto_subheader_cj" })}</h2>
        <p className="font-weight-bold">
          {intl.formatMessage({ id: "texto_body_cj" })}
        </p>
        <p>{intl.formatMessage({ id: "texto_body_pr_cj" })}</p>
        {/* <hr className="separadorCJ"></hr> */}
        <div>
          <h6>{intl.formatMessage({ id: "texto_footer_cupon" })}</h6>
          <span className="titleCoupon">
            {intl.formatMessage({ id: "texto_footer_codigo" })}
          </span>
        </div>
      </div>
    </div>
  )
}
