import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import HowToStyles from "./how-to.module.css"

export default function HowTo() {
  const intl = useIntl()
  return (
    <div className={["container"].join(" ")}>
      <div className={["row"].join(" ")}>
        <div
          className={["col", "text-center", "p-4", HowToStyles.title].join(" ")}
        >
          <h3>{intl.formatMessage({ id: "howto_title" })}</h3>
        </div>
      </div>
      <div className={["row", "text-center"].join(" ")}>
        <div
          className={[
            "pb-5",
            "col-sm-12",
            "col-md-4",
            "card",
            HowToStyles.card_how_to,
          ].join(" ")}
        >
          <img
            className={[HowToStyles.image, "card-img-top"].join(" ")}
            src={intl.formatMessage({ id: "howto_choose_image" })}
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">
              {intl.formatMessage({ id: "howto_choose" })}
            </h5>
            <p
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "howto_choose_description",
                }),
              }}
            ></p>
          </div>
        </div>
        <div
          className={[
            "pb-5",
            "col-sm-12",
            "col-md-4",
            "card",
            HowToStyles.card_how_to,
          ].join(" ")}
        >
          <img
            className={[HowToStyles.image, "card-img-top"].join(" ")}
            src={intl.formatMessage({ id: "howto_custom_image" })}
            alt="..."
          />
          <div className={["card-body"].join(" ")}>
            <h5 className="card-title">
              {intl.formatMessage({ id: "howto_custom" })}
            </h5>
            <p className="card-text">
              {intl.formatMessage({ id: "howto_custom_description" })}
            </p>
          </div>
        </div>
        <div
          className={[
            "pb-5",
            "col-sm-12",
            "col-md-4",
            "card",
            HowToStyles.card_how_to,
          ].join(" ")}
        >
          <img
            className={[HowToStyles.image, "card-img-top"].join(" ")}
            src={intl.formatMessage({ id: "howto_buy_image" })}
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">
              {intl.formatMessage({ id: "howto_buy" })}
            </h5>
            <p className="card-text">
              {intl.formatMessage({ id: "howto_buy_description" })}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
