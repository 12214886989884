import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import stylesCarnetJove from "./navBarRegala.css"
import CopyToClipboard from "react-copy-to-clipboard"
import PromoStyles from "./promo.module.css"

export default function NavBarRegala() {
  const intl = useIntl()
  return (
    <div className={["container-fluid", "navbarRegala"].join(" ")}>
      <div className={["row", "justify-content-center"].join(" ")}>
        <div
          className={[
            "col-lg-6",
            "col-md-12",
            "col-xs-12",
            "pt-3",
            "d-flex",
            "justify-content-around",
            "align-items-center",
            "text-white",
            "text-center",
          ].join(" ")}
        >
          <p className={["textDiscount"].join(" ")}>
            <span>-{intl.formatMessage({ id: "percentageDiscount" })}%</span>
            <p>{intl.formatMessage({ id: "cursosPracticas" })}</p>
            <p>
              <span>
                {intl.formatMessage({ id: "cuponText" })}:&nbsp;
                {intl.formatMessage({ id: "cupon" })}
              </span>
            </p>
            <p>
              <a
                className={["buttonDiscount"].join(" ")}
                href={intl.formatMessage({ id: "buyRecreational" })}
              >
                {intl.formatMessage({ id: "promo_buy" })}
              </a>
            </p>
          </p>
        </div>
      </div>
    </div>
  )
}
