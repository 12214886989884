import React from "react"
import Languageselector from "../components/languageselector"
import headerStyles from "./header.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "gatsby-plugin-intl"

const phone_icon = <FontAwesomeIcon icon={faPhone} size="1x" />

export default function Header() {
  const language_selector = <Languageselector />
  const intl = useIntl()

  return (
    <div className={headerStyles.header_top}>
      {}
      <div className="container-fluid">
        <div className="row">
          <div className={["col", headerStyles.container_logo_ep].join(" ")}>
            <a href={intl.formatMessage({ id: "enlace_header" })}>
              <figure className={headerStyles.figure_logo_ep}>
                <img
                  className={headerStyles.header_logo}
                  src="https://escolaportbarcelona.com/wp-content/uploads/2018/01/ESCOLA-PORT-BARCELONA-Logo-web.png"
                  alt=""
                />
              </figure>
            </a>
          </div>
          <div className="col d-flex justify-content-center">
            <div>
              <figure className={headerStyles.figure_logo_ep}>
                <img
                  className={
                    (headerStyles.header_logo, headerStyles.header_logo_central)
                  }
                  src={"/regala-el-mar-2021-eSCOLA-pORT.gif"}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className="col">
            <div className={headerStyles.modal_info_button_container}>
              {/* <div role="button" tabIndex={0} onClick={openModal} onKeyPress={openModal} className={headerStyles.modal_info_button}> */}
              {language_selector}
              <a
                href="tel:+34932210380"
                className={headerStyles.modal_info_button}
                role="button"
              >
                <div className="icons">
                  <div
                    className={[
                      headerStyles.contendor_icons_contact,
                      "pt-2",
                    ].join(" ")}
                  >
                    {phone_icon}
                  </div>
                </div>
                <div className={headerStyles.phone_number}>932 21 03 80</div>
              </a>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
