import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import footerStyles from "./footer.module.css"

export default function Footer( props ) {
  const intl = useIntl()

  return (
    <div className={[footerStyles.footer,"text-center","container-fluid"].join(" ")}>
        <p className={["mt-3","mb-0"].join(" ")}>Copyright © {new Date().getFullYear()} Escola Port | Formación Profesional del Mar. {intl.formatMessage({ id: "footer_copyright" })} Moll de la Marina, 11. Port Olímpic, 08005. Barcelona.</p>
        <p><a href={intl.formatMessage({ id: "footer_terms_conditions_link" })} target="_blank" rel="noreferrer help">{intl.formatMessage({ id: "footer_terms_conditions" })}</a> | <a href={intl.formatMessage({ id: "footer_cookies_privacity_link" })} target="_blank" rel="noreferrer help">{intl.formatMessage({ id: "footer_cookies_privacity" })}</a> - Tel.: <a href="tel:+34932210380">932 21 03 80</a> - <a href="tel:+34675206527">675 20 65 27</a></p>
    </div>
  )
}